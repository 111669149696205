.header{
  background:$medium-gray;
  .wrapper{
    width:100%;
  }
  @include media($medium-screen-up) {
    position:fixed;
    width: 100%;
    z-index: 99;
    -webkit-box-shadow: 9px -45px 60px 60px rgba(64,64,64,0.99);
    -moz-box-shadow: 9px -45px 60px 60px rgba(64,64,64,0.99);
    box-shadow: 9px -45px 60px 60px rgba(64,64,64,0.99);
    top:0;
    .wrapper{
      @include outer-container();
      //padding-left:2%;
      padding-right:2%;
    }
  }
}



.logo{
  //float:none;
  //height:rem(70);
  //width: 100%;
  width:rem(240);
  float:left;
  height:rem(56);
  margin-top:rem(20);
  margin-bottom:rem(0);
  height:100%;
  display:table;
  a{
    display:table-cell;
    vertical-align:middle;
    padding-left:rem(10);
    padding-right:rem(10);
  }

  @include media($medium-screen-up) {
    width:rem(268);
    float:left;
    height:rem(56);
    margin-top:rem(20);
    margin-bottom:rem(0);
    height:100%;
    a{
      padding-left:rem(20);
      padding-right:0;
    }

    img{
      max-width:100%;
    }
  }
  @include media($large-screen-up) {
    a{
      padding-left:0;
    }

  }
}

.mobile-btn{
  width:77px;
  height:100%;
  background:rgba(255,255,255,0.05);
  color:white;
  padding-left:0;
  padding-right:0;
  text-align:center;
  display:block;
  float:right;
  &.active{
    background:$blue;
  }
  @include media($medium-screen-up) {
    display:none;
  }
}

.nav{
  display:none;
  margin-bottom: 0;
  ul{
    width: 100%;
    display: block;

    li{
      width: 100%;
      margin: 0;
      a, a:visited, a:link{
        width: 100%;
        display: block;
        padding-left: 19px;
        padding-top: 25px;
        padding-bottom: 25px;
        background: grey;
        color:rgba(255,255,255,0.6);
        border-bottom: 2px solid $medium-gray;
      }
      a:hover, a:active{
        background:$blue;
        color: white;
      }
    }
    li.current_page_item, li.active{
      background:$blue;
      color: white;
    }
  }
  @include media($medium-screen-up) {
    display:block;
    width:33%;
    float:right;
    margin-bottom: 0;
    ul{
      display-style:none;
      width:auto;
      float:right;
      margin-top:rem(30);
      margin-bottom:rem(32);
      margin-left:rem(20);
      li{
        width:auto;
        float:left;
        margin-right:rem(15);
        text-transform:uppercase;
        a, a:link, a:visited{
          color:$light-gray;
          padding:0;
          background:transparent;
          border:none;
        }
        a:hover, a:active, a.active{
          color:$blue;
          background:transparent;
        }
      }
      li.current_page_item, li.active{
        background:transparent;
        a:link, a:visited{
          color:$blue;
          background:transparent;
        }
        a:hover, a:active{
          color:$blue;
          background:transparent;
        }
      }
    }
  }
}


.nav-mobile, li.mobile, .mobile-only{
  display:block;
  clear:both;
  @include media($medium-screen-up) {
    display:none;
  }
}

.contact-btn{
  display:none;

  @include media($medium-screen-up) {
    display:block;
  }
}
