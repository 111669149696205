// Typography
$base-font-family:  'Swiss721BT-LightCondensed', $helvetica;
$heading-font-family: 'FFInfoWebTextBook', $helvetica;;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #43CCFF;

$black: rgba(0,0,0,1);
$gray: rgba(255,255,255,0.1);

$dark-gray: #404040;
$medium-gray:rgba(83,83,83,1);
$soft-gray:rgba(100,100,100,1);
$light-gray:rgba(255,255,255,0.6);
$light-gray-2:rgba(64,64,64,0.6);


//Gutters & Margins - Mobile
$outer-gutter:rem(19);
$top-margin:rem(10);

//$green-70: rgba(0,178,40,0.7);

// Font Colors
$base-background-color: #fff;
$base-font-color: $black;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

//Colors
