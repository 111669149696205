* {
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
nav,
p strong{
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  font-weight: 600;
}

p {
  font-family: $base-font-family;
  margin: 0 0 $small-spacing;
  font-weight:400;
  font-size:17px;
  line-height:23px;

}

a {
  color: $action-color;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}



dl{
  font-size:14px;
  line-height:17px;
}

nav{
  font-size:18px;
  font-weight:400;
  text-transform:uppercase;
}


/*Headings*/


/*web fontflash*/
wf-loading  {
    //font-family: "droid-sans";
    visibility: hidden;
  }
  .wf-active  {
    visibility: visible;
  }
