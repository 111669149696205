aside.contact{
  display:none;
  padding-top:rem(90);
  padding-bottom:rem(90);
  @include linear-gradient(to rgba(255,255,255,0), rgba(10,10,10,0.5), $fallback: transparent );
  h2{
    font-size:30px;
    text-transform:uppercase;
  }

  span{
    width:60px;
    display:inline-block;
  }

  .btn{
    font-size:rem(16);
    background:$blue;
    @include transition(all 300ms);
    &:hover, &:active{
      background:$medium-gray;
    }
  }
}

.contact-content{
    @include linear-gradient(to rgba(255,255,255,0.3), rgba(10,10,10,0.5), $fallback: transparent );
    padding-top:20px;
}

.page.contact{
  .back-to-top{display:none}
}


.contact-profile{
  display: block;
  height: 269px;
  width: 180px;
  background: red;
  float: left;
  margin-right: 30px;
  overflow:hidden;
  img{
    max-width: 135%;
    margin-left: -12.5%;
  }
}
