/**
 * @license
 * MyFonts Webfont Build ID 3138422, 2015-12-09T05:48:35-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed ../fonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Swiss721BT-LightCondensed by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/swiss-721/light-condensed/
 * Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 * Licensed pageviews: 10,000
 *
 * Webfont: FF Info Web Text Book by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-info/ottext-book/
 * Copyright: 2011 Erik Spiekermann, Ole Schaefer published by FSI FontShop International GmbH
 * Licensed pageviews: 50,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3138422
 *
 * © 2015 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2fe376");


@font-face {
  font-family: 'Swiss721BT-LightCondensed';
  src: url('../fonts/2FE376_0_0.eot');
  src: url('../fonts/2FE376_0_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/2FE376_0_0.woff2') format('woff2'),
       url('../fonts/2FE376_0_0.woff') format('woff'),
       url('../fonts/2FE376_0_0.ttf') format('truetype');
     }


@font-face {
    font-family: 'FFInfoWebTextBook';
    src: url('../fonts/2FE376_1_0.eot');
    src: url('../fonts/2FE376_1_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/2FE376_1_0.woff2') format('woff2'),
         url('../fonts/2FE376_1_0.woff') format('woff'),
         url('../fonts/2FE376_1_0.ttf') format('truetype');
    font-weight:400;
       }
