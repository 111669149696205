/*Posts Normal*/



.posts{
  article{
    margin-bottom:0;
  }

  .gallery {
    width:100%;
    @include aspect-ratio(21,1);
  }

  .content-area{
    @include span-columns(12);
    @include media ($medium-screen-up){
      @include span-columns(12);
    }
  }
  .text-area{
    @include span-columns(12);
    @include media ($medium-screen-up){
      @include span-columns(5);
    }
  }
  .img-area{
    @include span-columns(12);
    padding-left: $outer-gutter;
    padding-right: $outer-gutter;
    @include media ($medium-screen-up){
      @include span-columns(6);
      @include shift(1);
      padding-left: 0;
      padding-right: 0;
    }
  }
  .img-area.mobile{
    opacity:0;
    @include transition(opacity 300ms ease);
    @include media ($medium-screen-up){
      display:none;
    }
  }
  .img-area.desktop{
    opacity:0;
    @include transition(opacity 300ms ease);
    @include media ($medium-screen-down){
      display:none;
    }
  }


  header{
    padding-bottom:0;

    @include media ($medium-screen-up){
      padding-left:0;
    }
  }

  h1{
    margin-bottom:rem(5);
  }
  h2{
    //color:$light-gray;
    color:white;
  }

  section.extended{
    width:100%;

    @include media ($medium-screen-up){
      padding-left:0;
      margin-top:0;
    }
  }
}

/*Post Active*/

.posts{
  article.active{
    background:$gray;
    @include media ($medium-screen-up){
      padding-bottom:0;
    }
  }
  article.active header{
    background:none;
    padding-bottom:0;
  }

  article.active .content-area{
    @include span-columns(12);
  }

  article.active .img-area{
    opacity:1;

  }
  article.active .img-area.mobile .gallery{
    min-height: 270px;
    max-height: 270px;
    height: 270px;
  }


}
