/* ================= *\
Codrops implementation
\* ================= */
.bt-menu-trigger {
	position: relative;
  display: inline-block;;
	width: 33px;
	height: 30px;
	cursor: pointer;
  margin:20px auto;
  font-size:14px;
  text-transform:uppercase;
  padding-left:20px;
  padding-top:10px;
  z-index: 999;
	line-height: 21px;
}

.bt-menu-trigger span {
	position: absolute;
	top: 50%;
	left: 0;
	display: block;
	width: 33px;
	height: 4px;
	margin-top: 0;
	background-color: $blue;
	font-size: 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.bt-menu-trigger.open span {
	background-color: transparent;
}

.bt-menu-trigger span:before,
.bt-menu-trigger span:after {
	position: absolute;
	left: 0;
	width: 33px;
	height: 100%;
	background: $blue;
	content: '';
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.bt-menu-trigger.open span:before,
.bt-menu-trigger.open span:after {
  background:white;
}

.bt-menu-trigger span:before {
	-webkit-transform: translateY(-300%);
	transform: translateY(-300%);
}

.bt-menu-trigger span:after {
	-webkit-transform: translateY(300%);
	transform: translateY(300%);
}

.bt-menu-trigger.open span:before {
	-webkit-transform: translateY(0) rotate(45deg);
	transform: translateY(0) rotate(45deg);
}

.bt-menu-trigger.open span:after {
	/* -webkit-transform: translateY(0) rotate(-45deg); */
	transform: translateY(0) rotate(-45deg);
}
