main.page{
  padding-top:rem(26);
  @include media($medium-screen-up) {
      padding-top:rem(60);
  }
  &.alt{
    @include media($medium-screen-up) {
        padding-top:rem(0);
    }
  }
}

h1.page{
  width:100%;
  font-size:18px;
  text-transform:uppercase;
  color:$light-gray;
  margin-bottom:0;
  @include media($medium-screen-up) {
    font-size:30px;
    color:white;
    @include span-columns(6);
    @include shift(1);
  }
}

h1.mobile-only{
  display:block;
  @include media($medium-screen-up) {
    display:none;
  }
}

article.page{
    width:100%;
    @include media($medium-screen-up) {
      @include span-columns(6);
      @include shift(1);
    }
}


section.about-extra{
  background:$gray;
  article{
    @include media($medium-screen-up) {
      @include span-columns(4);
      @include shift(1);
    }

  }
  h3{
    text-transform:uppercase;
    padding-top: $base-spacing;
    padding-bottom: $small-spacing
  }
  dl{
    border-bottom:1px solid $light-gray-2;
  }
  dt, dd{
    @extend p;
    width:100%;
    margin:0;
    color:white;
  }
  dt{
    border-top:1px solid $light-gray-2;
    padding-top: $small-spacing;
  }
  dd{
    padding-bottom: $small-spacing;
    dl{
      margin:0;
      border:none;
      dt, dd{
        border:none;
        padding:0;
      }
      dd{
        color: #929292;
      }
    }
  }
}

.profile{
  width:160px;
  margin-bottom:70px;
  figcaption{
    padding-top:10px;
    padding-bottom:10px;
    text-transform:uppercase;
    text-align:center;
    background:$soft-gray;
    font-family: $heading-font-family;
  }
}
