@import "../../bower_components/bourbon/app/assets/stylesheets/_bourbon";
@import "base/grid-settings";
@import "../../bower_components/neat/app/assets/stylesheets/_neat";
@import "base/base";

/*Admin bar*/
#wpadminbar{
  opacity:0.2;
  margin-top: -15px;
  transition: all 200ms ease-in;
  &:hover{
    opacity:1;
    margin-top:0;
  }
}
/*Body*/
html{
  margin-top:0 !important;
  font-size:100%;
  height:100%;
  overflow-x: hidden;
  background:$dark-gray;

}

body {
  background:$dark-gray;
  color:white;
  overflow-x: hidden;
  max-width: 100%;

  @include media($medium-screen-up) {
    margin-top: rem(90);
  }
}

a {
  text-decoration: none;
  cursor:pointer;
}

.wrapper{
  @include outer-container();
}


main{
  //@include outer-container;

}
article{
  margin-bottom:rem(30);
}

section.top{
  //height:rem(60);
  margin-bottom: 20px;
}


footer{
  padding-top:rem(20);
  padding-bottom:rem(50);
  background:url(../img/af-footer.svg) no-repeat;
  background-position: 10px;
  background-size: 160px;
  @include media($medium-screen-up) {
    background-position: center;

  }
}


.back-to-top{
  float:right;
  color:white;
  margin-right: 5%;
  padding-left: rem(18);
  &:before{
    content:'';
    background:url(../img/arrow-up.svg) no-repeat;
    width: rem(14);
    height: rem(5);
    display: inline-block;
    position: relative;
    top: rem(-2);
    margin-right: rem(7);
  }
}




h1.typewrite{
  //white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display:block;
  //animation: type 3s steps(40, end);
  //animation-delay: 1s;
}

dt .typewrite, {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display:block;
  //animation: type 2s steps(55, end);
  //animation-delay: 1s;
}

dd .typewrite {
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  display:block;
  float: left;
  margin-right: 5px;
  //animation: type2 2s steps(55, end);
  //animation-delay: 1s;
}


@keyframes type{
  from { width: 0;}
}

@keyframes type2{
  0%{width: 0;}
  50%{width: 0;}
  100%{ width: 100; }
}




@import "parts/menu";
@import "parts/contact";
@import "parts/posts";
@import "parts/page"
