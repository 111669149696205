.link-arrow {
    &:before{
      content: ' ';
      position: relative;
      height: 11px;
      width: 0px;
      border-top: rem(4) solid transparent;
      border-left: rem(5) solid #00B228;
      border-right: 0px transparent;
      border-bottom: rem(4) solid transparent;
      line-height: 0;
      padding-right: rem(3);
      font-size: 2px;
      top: rem(-5);
    }
    a:link, a:visited{
      color: #00B228;
    	font-weight:600;
    	text-decoration:none;
    }
}
