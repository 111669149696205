@import "../../../bower_components/neat/app/assets/stylesheets/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

// Neat Overrides
$column: 95px;
$gutter: 5px;
$grid-columns: 12;
$max-width: em(1200);

// Neat Breakpoints

$medium-screen: em(640);
$large-screen: em(860);

$medium-screen-down: new-breakpoint(max-width $large-screen 4);
$medium-screen-up: new-breakpoint(min-width $large-screen 12);
$large-screen-up: new-breakpoint(min-width $large-screen 12);
