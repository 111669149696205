.accordion {
  article{
    padding: 0;
    border-bottom: 0;
    position:relative;
    //@include outer-container();
    display:block;
    @include media($medium-screen-up) {
      @include transition(all 200ms ease);
      padding-left:2%;
      padding-right:2%;
    }
    &:hover{

      @include media($medium-screen-up) {
        background:$medium-gray;
        cursor:pointer;
        h1{
          color:$blue;
        }
      }
    }
  }
  article.active{
    @include media($medium-screen-up) {
      padding-bottom:rem(41);
      background:$medium-gray;

    }
    h1{
      //color:$blue;
    }
    &:hover{
      cursor:pointer;
      h1{
        color:white;
      }
    }
  }


  .article__wrapper{
    @include media($medium-screen-up) {
      @include outer-container();

    }
  }

  /* Gallery */
  .gallery{
    display: block;
    position: relative;
    @include aspect-ratio(6,1.1);
    @include media($medium-screen-up) {
      float: left;
      width: 66%;

    }
  }
  article.active .gallery{
    @include aspect-ratio(16,9);
  }

 /* Gallery - Carousel */
 .owl-carousel{
   @extend .content;
   overflow:hidden;
   @include media($medium-screen-up) {
     width:100%;
   }
   @include media($medium-screen-up) {
     //@include span-columns(6, block-collapse);
     @include shift(0);
   }
   @include media($large-screen-up) {
     //@include span-columns(8, block-collapse);
     @include shift(0);
   }
 }
 /* Gallery - Items */
 .item{
    @include aspect-ratio(5,1.2);
    span{
      background-size:cover;
      background-position:center;
    }
  }

  article.active .item{
    @include aspect-ratio(16,9);

  }

  .owl-nav{
    display:none;
  }

  article.active .owl-nav{
    display:block;
  }
  .owl-prev, .owl-next{
    //position:absolute;
    //top:50%;
    padding-top:rem(7)
  }
  .owl-prev{
    width: auto;
    float: left;
  }
  .owl-next{
    width: auto;
    float: right;
  }
  .info{
    background:$soft-gray;
    width:100%;
    padding-top:rem(0);
    padding-bottom:rem(0);
    text-align:center;
    position:relative;
    z-index:0;
    opacity:0;
    visibility:hidden;
    margin-top: 0;
    height:rem(40);
    padding-top:rem(7);
    padding-bottom:rem(10);
    @include transition(all 200ms ease);
    @include media($medium-screen-up) {
      position:relative;
      //top: 32vw;
      width:100%;
      z-index:0;
      margin-top: 0;

      //@include span-columns(8, block-collapse);
      //@include shift(0);
    }
    @include media($medium-screen-up) {
      @include span-columns(6, block-collapse);
      @include shift(0);
      display: none;
    }
    @include media($large-screen-up) {
      @include span-columns(8, block-collapse);
      @include shift(0);
      display: none;
    }
  }

  article.active .info{
    opacity:1;
    visibility:visible;
    @include media($medium-screen-up) {
      width:100%;
      display: block;
    }
  }

  .info-side{
    display:none;
    @include media($medium-screen-up) {
      position:absolute;
      display:block;
      top: 32vw;
      z-index:9;
      @include span-columns(4, block-collapse);
      @include shift(0);
    }
  }

  /* Content Area*/
  .content-area {
    @include media($medium-screen-up) {
      @include span-columns(4, block-collapse);
      width: 43%;
      min-height: 100%;
      right: 0;
      position: relative;
    }
    @include media($large-screen-up) {
      @include span-columns(4, block-collapse);
      width: 33%;

    }

  }

  article.active  .content-area {
    @include media($medium-screen-up) {
      @include span-columns(4, block-collapse);
      position: relative;
      width: 43%;
    }
    @include media($large-screen-up) {
      @include span-columns(4, block-collapse);
      width: 33%;

    }
  }

  /* Header */
  header{
    font-weight:bold;
    padding-top:$top-margin;
    padding-bottom:rem(0);
    padding-left:$outer-gutter;
    padding-right:$outer-gutter;
    text-transform:uppercase;
    z-index: 1;

    min-height:100%;
    //background:$dark-gray;
    width:100%;
    @include transition(all 200ms ease);


    @include media($medium-screen-up) {
      display:block;
      height:100%;
      position: relative;
      width:500%;
    }
    dt{
      @include media($medium-screen-up) {
        @include span-columns(2);
      }

    }
    dd{
      @include media($medium-screen-up) {
        @include span-columns(9);
        @include omega();
      }

    }

  }

  article.active header{
    background:$medium-gray;
    padding-bottom:rem(18);
    @include media($medium-screen-up) {
      background:$soft-gray;
      position:relative;
      height:auto;
    }

  }

  /*Section Extended*/

  section.extended{
    margin-top:rem(21);
    padding-left: $outer-gutter;
    padding-right: $outer-gutter;
    padding-left:rem(20);

  }
  /* Mobile Button */
  .btn{
    padding-left: $outer-gutter;
    padding-right: $outer-gutter;
    margin-left:$outer-gutter;
    display:inline-block;
    font-size: 14px;
    @include media($medium-screen-up) {
      display:none;
    }
   .open{
       display:block;
     }
     .close{
       display:none;
     }
  }
  article.active .btn{
    .open{
      display:none;
    }
    .close{
      display:block;
    }
   }
}

.arrow-left, .arrow-right{
  height: 12px;
  width: 10px;
  display: inline-block;
  top:2px;
  display: block;
  margin-top: 6px;
}
.arrow-left{
  background:url(../img/arrow-left.svg) no-repeat;
  margin-left: 5px;
  margin-right:8px;
  float: left;
}
.arrow-right{
  background:url(../img/arrow-right.svg) no-repeat;
  margin-right: 5px;
  margin-left:8px;
  float: right;
}

.arrow-text{
  @include media($medium-screen-down) {
    display:none;
  }
}
