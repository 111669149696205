ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

}

dl {
  @include outer-container();
  margin-left:0;
  font-size:rem(14);
  line-height:rem(17);
  margin-bottom:0;
  dt {
    font-weight: bold;
    //margin-top: $small-spacing;
    color:$light-gray;
    @include span-columns(5);
  }

  dd {
    margin: 0;
    @include span-columns(7);
    @include omega();
  }
}



ul.list-style--plus{
  list-style:none;
  li:before{
    content: "+";
    margin-right: rem(6);
    padding-top:rem(2);


  }
}

ul.list-style--arrow{
  list-style:none;
  li:before{
    content: ' ';
    position: relative;
    height: 11px;
    width: 0px;
    border-top: 0.25rem solid transparent;
    border-left: 0.3125rem solid #00B228;
    border-right: 0px transparent;
    border-bottom: 0.25rem solid transparent;
    line-height: 0;
    padding-right: 0.1875rem;
    font-size: 2px;
    top: -0.3125rem;


  }
}
