@charset "UTF-8";
@import url("//hello.myfonts.net/count/2fe376");
html { box-sizing: border-box; }

*, *::after, *::before { box-sizing: inherit; }

button, input[type="button"], input[type="reset"], input[type="submit"], button { -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; -o-appearance: none; appearance: none; -webkit-font-smoothing: antialiased; background-color: #43CCFF; border-radius: 0; border: none; color: #fff; cursor: pointer; display: inline-block; font-family: "Swiss721BT-LightCondensed", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; font-size: 1em; font-weight: 600; line-height: 1; padding: 0.75em 1em; text-decoration: none; user-select: none; vertical-align: middle; white-space: nowrap; }
button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus, button:hover, button:focus { background-color: #00b3f6; color: #fff; }
button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled, button:disabled { cursor: not-allowed; opacity: 0.5; }

.btn { background: rgba(255, 255, 255, 0.1); padding: 0.5rem; font-weight: 600; font-size: 1.125em; line-height: 1.4375rem; color: white; margin-top: 0.625rem; display: inline-block; }
.btn:link, .btn:visited { color: white; }
.btn:hover, .btn:active { color: white; background: #43CCFF; }

/*SSBA Buttons*/
.ssbp--theme-2 .ssbp-btn:hover, .ssbp--theme-2 .ssbp-btn:focus, .ssbp--theme-2 .ssbp-btn:active, .ssbp--theme-2 .ssbp-btn:visited { color: #6e6e6e; }

.ssbp-list li:first-child a { margin-left: 0 !important; }

/*Custom social*/
.block--social span { display: none; }
.block--social svg { width: 25px; fill: white; -webkit-transition: fill 300ms ease; -moz-transition: fill 300ms ease; transition: fill 300ms ease; }
.block--social a:link svg, .block--social a:visited svg { width: 25px; fill: white; }
.block--social a:hover svg, .block--social a:active svg { width: 25px; fill: #43CCFF; }

fieldset { background-color: rgba(255, 255, 255, 0.6); border: 1px solid rgba(255, 255, 255, 0.6); margin: 0 0 0.75em; padding: 1.5em; }

input, label, select { display: block; font-family: "Swiss721BT-LightCondensed", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; font-size: 1em; }

label { font-weight: 600; margin-bottom: 0.375em; }
label.required::after { content: "*"; }
label abbr { display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, select[multiple=multiple], textarea { background-color: #fff; border: 1px solid rgba(255, 255, 255, 0.6); border-radius: 0; box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06); box-sizing: border-box; font-family: "Swiss721BT-LightCondensed", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; font-size: 1em; margin-bottom: 0.75em; padding: 0.5em; transition: border-color; width: 100%; }
input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover, select[multiple=multiple]:hover, textarea:hover { border-color: rgba(230, 230, 230, 0.6); }
input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus, select[multiple=multiple]:focus, textarea:focus { border-color: #43CCFF; box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(42, 197, 255, 0.7); outline: none; }

textarea { resize: vertical; }

input[type="search"] { -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; -o-appearance: none; appearance: none; }

input[type="checkbox"], input[type="radio"] { display: inline; margin-right: 0.375em; }

input[type="file"] { padding-bottom: 0.75em; width: 100%; }

select { margin-bottom: 1.5em; max-width: 100%; width: auto; }

ul, ol { list-style-type: none; margin: 0; padding: 0; }
dl { max-width: 75em; margin-left: auto; margin-right: auto; margin-left: 0; font-size: 0.875rem; line-height: 1.0625rem; margin-bottom: 0; }
dl::after { clear: both; content: ""; display: table; }
dl dt { font-weight: bold; color: rgba(255, 255, 255, 0.6); float: left; display: block; margin-right: 0.41841%; width: 41.42259%; }
dl dt:last-child { margin-right: 0; }
dl dd { margin: 0; float: left; display: block; margin-right: 0.41841%; width: 58.159%; margin-right: 0; }
dl dd:last-child { margin-right: 0; }

ul.list-style--plus { list-style: none; }
ul.list-style--plus li:before { content: "+"; margin-right: 0.375rem; padding-top: 0.125rem; }

ul.list-style--arrow { list-style: none; }
ul.list-style--arrow li:before { content: ' '; position: relative; height: 11px; width: 0px; border-top: 0.25rem solid transparent; border-left: 0.3125rem solid #00B228; border-right: 0px transparent; border-bottom: 0.25rem solid transparent; line-height: 0; padding-right: 0.1875rem; font-size: 2px; top: -0.3125rem; }

table { -webkit-font-feature-settings: "kern", "liga", "tnum"; -moz-font-feature-settings: "kern", "liga", "tnum"; -ms-font-feature-settings: "kern", "liga", "tnum"; font-feature-settings: "kern", "liga", "tnum"; border-collapse: collapse; margin: 0.75em 0; table-layout: fixed; width: 100%; }

th { border-bottom: 1px solid rgba(217, 217, 217, 0.6); font-weight: 600; padding: 0.75em 0; text-align: left; }

td { border-bottom: 1px solid rgba(255, 255, 255, 0.6); padding: 0.75em 0; }

tr, td, th { vertical-align: middle; }

/** @license MyFonts Webfont Build ID 3138422, 2015-12-09T05:48:35-0500  The fonts listed in this notice are subject to the End User License Agreement(s) entered into by the website owner. All other parties are explicitly restricted from using the Licensed ../fonts(s).  You may obtain a valid license at the URLs below.  Webfont: Swiss721BT-LightCondensed by Bitstream URL: http://www.myfonts.com/fonts/bitstream/swiss-721/light-condensed/ Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved. Licensed pageviews: 10,000  Webfont: FF Info Web Text Book by FontFont URL: http://www.myfonts.com/fonts/fontfont/ff-info/ottext-book/ Copyright: 2011 Erik Spiekermann, Ole Schaefer published by FSI FontShop International GmbH Licensed pageviews: 50,000   License: http://www.myfonts.com/viewlicense?type=web&buildid=3138422  © 2015 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face { font-family: 'Swiss721BT-LightCondensed'; src: url("../fonts/2FE376_0_0.eot"); src: url("../fonts/2FE376_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/2FE376_0_0.woff2") format("woff2"), url("../fonts/2FE376_0_0.woff") format("woff"), url("../fonts/2FE376_0_0.ttf") format("truetype"); }
@font-face { font-family: 'FFInfoWebTextBook'; src: url("../fonts/2FE376_1_0.eot"); src: url("../fonts/2FE376_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/2FE376_1_0.woff2") format("woff2"), url("../fonts/2FE376_1_0.woff") format("woff"), url("../fonts/2FE376_1_0.ttf") format("truetype"); font-weight: 400; }
* { text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); text-rendering: optimizeLegibility !important; -webkit-font-smoothing: antialiased !important; }

body { -webkit-font-feature-settings: "kern", "liga", "pnum"; -moz-font-feature-settings: "kern", "liga", "pnum"; -ms-font-feature-settings: "kern", "liga", "pnum"; font-feature-settings: "kern", "liga", "pnum"; -webkit-font-smoothing: antialiased; color: black; font-family: "Swiss721BT-LightCondensed", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; font-size: 1em; line-height: 1.5; }

h1, h2, h3, h4, h5, h6, dl, nav, p strong, section.about-extra dt strong, section.about-extra dd strong { font-family: "FFInfoWebTextBook", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; font-size: 1em; line-height: 1.2; margin: 0 0 0.75em; font-weight: 600; }

p, section.about-extra dt, section.about-extra dd { font-family: "Swiss721BT-LightCondensed", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; margin: 0 0 0.75em; font-weight: 400; font-size: 17px; line-height: 23px; }

a { color: #43CCFF; text-decoration: none; transition: color 0.1s linear; }
a:active, a:focus, a:hover { color: #00b3f6; }
a:active, a:focus { outline: none; }

hr { border-bottom: 1px solid rgba(255, 255, 255, 0.6); border-left: none; border-right: none; border-top: none; margin: 1.5em 0; }

img, picture { margin: 0; max-width: 100%; }

dl { font-size: 14px; line-height: 17px; }

nav { font-size: 18px; font-weight: 400; text-transform: uppercase; }

/*Headings*/
/*web fontflash*/
wf-loading { visibility: hidden; }

.wf-active { visibility: visible; }

.profile-pic { position: relative; }
.profile-pic:before { display: block; content: ""; width: 100%; padding-top: 100%; }
.profile-pic > .content, .accordion .profile-pic > .owl-carousel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; }
.profile-pic img { width: 100%; }

.link-arrow:before { content: ' '; position: relative; height: 11px; width: 0px; border-top: 0.25rem solid transparent; border-left: 0.3125rem solid #00B228; border-right: 0px transparent; border-bottom: 0.25rem solid transparent; line-height: 0; padding-right: 0.1875rem; font-size: 2px; top: -0.3125rem; }
.link-arrow a:link, .link-arrow a:visited { color: #00B228; font-weight: 600; text-decoration: none; }

.accordion { /* Gallery */ /* Gallery - Carousel */ /* Gallery - Items */ /* Content Area*/ /* Header */ /*Section Extended*/ /* Mobile Button */ }
.accordion article { padding: 0; border-bottom: 0; position: relative; display: block; }
@media screen and (min-width: 53.75em) { .accordion article { -webkit-transition: all 200ms ease; -moz-transition: all 200ms ease; transition: all 200ms ease; padding-left: 2%; padding-right: 2%; } }
@media screen and (min-width: 53.75em) { .accordion article:hover { background: #535353; cursor: pointer; }
  .accordion article:hover h1 { color: #43CCFF; } }
@media screen and (min-width: 53.75em) { .accordion article.active { padding-bottom: 2.5625rem; background: #535353; } }
.accordion article.active:hover { cursor: pointer; }
.accordion article.active:hover h1 { color: white; }
@media screen and (min-width: 53.75em) { .accordion .article__wrapper { max-width: 75em; margin-left: auto; margin-right: auto; }
  .accordion .article__wrapper::after { clear: both; content: ""; display: table; } }
.accordion .gallery { display: block; position: relative; position: relative; }
.accordion .gallery:before { display: block; content: ""; width: 100%; padding-top: 18.33333%; }
.accordion .gallery > .content, .accordion .gallery > .owl-carousel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; }
@media screen and (min-width: 53.75em) { .accordion .gallery { float: left; width: 66%; } }
.accordion article.active .gallery { position: relative; }
.accordion article.active .gallery:before { display: block; content: ""; width: 100%; padding-top: 56.25%; }
.accordion article.active .gallery > .content, .accordion article.active .gallery > .owl-carousel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; }
.accordion .owl-carousel { overflow: hidden; }
@media screen and (min-width: 53.75em) { .accordion .owl-carousel { width: 100%; } }
@media screen and (min-width: 53.75em) { .accordion .owl-carousel { margin-left: 0%; } }
@media screen and (min-width: 53.75em) { .accordion .owl-carousel { margin-left: 0%; } }
.accordion .item { position: relative; }
.accordion .item:before { display: block; content: ""; width: 100%; padding-top: 24%; }
.accordion .item > .content, .accordion .item > .owl-carousel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; }
.accordion .item span { background-size: cover; background-position: center; }
.accordion article.active .item { position: relative; }
.accordion article.active .item:before { display: block; content: ""; width: 100%; padding-top: 56.25%; }
.accordion article.active .item > .content, .accordion article.active .item > .owl-carousel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; }
.accordion .owl-nav { display: none; }
.accordion article.active .owl-nav { display: block; }
.accordion .owl-prev, .accordion .owl-next { padding-top: 0.4375rem; }
.accordion .owl-prev { width: auto; float: left; }
.accordion .owl-next { width: auto; float: right; }
.accordion .info { background: #646464; width: 100%; padding-top: 0rem; padding-bottom: 0rem; text-align: center; position: relative; z-index: 0; opacity: 0; visibility: hidden; margin-top: 0; height: 2.5rem; padding-top: 0.4375rem; padding-bottom: 0.625rem; -webkit-transition: all 200ms ease; -moz-transition: all 200ms ease; transition: all 200ms ease; }
@media screen and (min-width: 53.75em) { .accordion .info { position: relative; width: 100%; z-index: 0; margin-top: 0; } }
@media screen and (min-width: 53.75em) { .accordion .info { float: left; display: block; width: 50.20921%; margin-left: 0%; display: none; }
  .accordion .info:last-child { width: 49.79079%; } }
@media screen and (min-width: 53.75em) { .accordion .info { float: left; display: block; width: 66.94561%; margin-left: 0%; display: none; }
  .accordion .info:last-child { width: 66.5272%; } }
.accordion article.active .info { opacity: 1; visibility: visible; }
@media screen and (min-width: 53.75em) { .accordion article.active .info { width: 100%; display: block; } }
.accordion .info-side { display: none; }
@media screen and (min-width: 53.75em) { .accordion .info-side { position: absolute; display: block; top: 32vw; z-index: 9; float: left; display: block; width: 33.4728%; margin-left: 0%; }
  .accordion .info-side:last-child { width: 33.05439%; } }
@media screen and (min-width: 53.75em) { .accordion .content-area { float: left; display: block; width: 33.4728%; width: 43%; min-height: 100%; right: 0; position: relative; }
  .accordion .content-area:last-child { width: 33.05439%; } }
@media screen and (min-width: 53.75em) { .accordion .content-area { float: left; display: block; width: 33.4728%; width: 33%; }
  .accordion .content-area:last-child { width: 33.05439%; } }
@media screen and (min-width: 53.75em) { .accordion article.active .content-area { float: left; display: block; width: 33.4728%; position: relative; width: 43%; }
  .accordion article.active .content-area:last-child { width: 33.05439%; } }
@media screen and (min-width: 53.75em) { .accordion article.active .content-area { float: left; display: block; width: 33.4728%; width: 33%; }
  .accordion article.active .content-area:last-child { width: 33.05439%; } }
.accordion header { font-weight: bold; padding-top: 0.625rem; padding-bottom: 0rem; padding-left: 1.1875rem; padding-right: 1.1875rem; text-transform: uppercase; z-index: 1; min-height: 100%; width: 100%; -webkit-transition: all 200ms ease; -moz-transition: all 200ms ease; transition: all 200ms ease; }
@media screen and (min-width: 53.75em) { .accordion header { display: block; height: 100%; position: relative; width: 500%; } }
@media screen and (min-width: 53.75em) { .accordion header dt { float: left; display: block; margin-right: 0.41841%; width: 16.31799%; }
  .accordion header dt:last-child { margin-right: 0; } }
@media screen and (min-width: 53.75em) { .accordion header dd { float: left; display: block; margin-right: 0.41841%; width: 74.8954%; margin-right: 0; }
  .accordion header dd:last-child { margin-right: 0; } }
.accordion article.active header { background: #535353; padding-bottom: 1.125rem; }
@media screen and (min-width: 53.75em) { .accordion article.active header { background: #646464; position: relative; height: auto; } }
.accordion section.extended { margin-top: 1.3125rem; padding-left: 1.1875rem; padding-right: 1.1875rem; padding-left: 1.25rem; }
.accordion .btn { padding-left: 1.1875rem; padding-right: 1.1875rem; margin-left: 1.1875rem; display: inline-block; font-size: 14px; }
@media screen and (min-width: 53.75em) { .accordion .btn { display: none; } }
.accordion .btn .open { display: block; }
.accordion .btn .close { display: none; }
.accordion article.active .btn .open { display: none; }
.accordion article.active .btn .close { display: block; }

.arrow-left, .arrow-right { height: 12px; width: 10px; display: inline-block; top: 2px; display: block; margin-top: 6px; }

.arrow-left { background: url(../img/arrow-left.svg) no-repeat; margin-left: 5px; margin-right: 8px; float: left; }

.arrow-right { background: url(../img/arrow-right.svg) no-repeat; margin-right: 5px; margin-left: 8px; float: right; }

@media screen and (max-width: 53.75em) { .arrow-text { display: none; } }

/* ================= *\
Codrops implementation
\* ================= */
.bt-menu-trigger { position: relative; display: inline-block; width: 33px; height: 30px; cursor: pointer; margin: 20px auto; font-size: 14px; text-transform: uppercase; padding-left: 20px; padding-top: 10px; z-index: 999; line-height: 21px; }

.bt-menu-trigger span { position: absolute; top: 50%; left: 0; display: block; width: 33px; height: 4px; margin-top: 0; background-color: #43CCFF; font-size: 0px; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-transition: background-color 0.3s; transition: background-color 0.3s; }

.bt-menu-trigger.open span { background-color: transparent; }

.bt-menu-trigger span:before, .bt-menu-trigger span:after { position: absolute; left: 0; width: 33px; height: 100%; background: #43CCFF; content: ''; -webkit-transition: -webkit-transform 0.3s; transition: transform 0.3s; }

.bt-menu-trigger.open span:before, .bt-menu-trigger.open span:after { background: white; }

.bt-menu-trigger span:before { -webkit-transform: translateY(-300%); transform: translateY(-300%); }

.bt-menu-trigger span:after { -webkit-transform: translateY(300%); transform: translateY(300%); }

.bt-menu-trigger.open span:before { -webkit-transform: translateY(0) rotate(45deg); transform: translateY(0) rotate(45deg); }

.bt-menu-trigger.open span:after { /* -webkit-transform: translateY(0) rotate(-45deg); */ transform: translateY(0) rotate(-45deg); }

/*Admin bar*/
#wpadminbar { opacity: 0.2; margin-top: -15px; transition: all 200ms ease-in; }
#wpadminbar:hover { opacity: 1; margin-top: 0; }

/*Body*/
html { margin-top: 0 !important; font-size: 100%; height: 100%; overflow-x: hidden; background: #404040; }

body { background: #404040; color: white; overflow-x: hidden; max-width: 100%; }
@media screen and (min-width: 53.75em) { body { margin-top: 5.625rem; } }

a { text-decoration: none; cursor: pointer; }

.wrapper { max-width: 75em; margin-left: auto; margin-right: auto; }
.wrapper::after { clear: both; content: ""; display: table; }

article { margin-bottom: 1.875rem; }

section.top { margin-bottom: 20px; }

footer { padding-top: 1.25rem; padding-bottom: 3.125rem; background: url(../img/af-footer.svg) no-repeat; background-position: 10px; background-size: 160px; }
@media screen and (min-width: 53.75em) { footer { background-position: center; } }

.back-to-top { float: right; color: white; margin-right: 5%; padding-left: 1.125rem; }
.back-to-top:before { content: ''; background: url(../img/arrow-up.svg) no-repeat; width: 0.875rem; height: 0.3125rem; display: inline-block; position: relative; top: -0.125rem; margin-right: 0.4375rem; }

h1.typewrite { overflow: hidden; width: 100%; display: block; }

dt .typewrite { white-space: nowrap; overflow: hidden; width: 100%; display: block; }

dd .typewrite { white-space: nowrap; overflow: hidden; width: auto; display: block; float: left; margin-right: 5px; }

@keyframes type { from { width: 0; } }
@keyframes type2 { 0% { width: 0; }
  50% { width: 0; }
  100% { width: 100; } }
.header { background: #535353; }
.header .wrapper { width: 100%; }
@media screen and (min-width: 53.75em) { .header { position: fixed; width: 100%; z-index: 99; -webkit-box-shadow: 9px -45px 60px 60px rgba(64, 64, 64, 0.99); -moz-box-shadow: 9px -45px 60px 60px rgba(64, 64, 64, 0.99); box-shadow: 9px -45px 60px 60px rgba(64, 64, 64, 0.99); top: 0; }
  .header .wrapper { max-width: 75em; margin-left: auto; margin-right: auto; padding-right: 2%; }
  .header .wrapper::after { clear: both; content: ""; display: table; } }

.logo { width: 15rem; float: left; height: 3.5rem; margin-top: 1.25rem; margin-bottom: 0rem; height: 100%; display: table; }
.logo a { display: table-cell; vertical-align: middle; padding-left: 0.625rem; padding-right: 0.625rem; }
@media screen and (min-width: 53.75em) { .logo { width: 16.75rem; float: left; height: 3.5rem; margin-top: 1.25rem; margin-bottom: 0rem; height: 100%; }
  .logo a { padding-left: 1.25rem; padding-right: 0; }
  .logo img { max-width: 100%; } }
@media screen and (min-width: 53.75em) { .logo a { padding-left: 0; } }

.mobile-btn { width: 77px; height: 100%; background: rgba(255, 255, 255, 0.05); color: white; padding-left: 0; padding-right: 0; text-align: center; display: block; float: right; }
.mobile-btn.active { background: #43CCFF; }
@media screen and (min-width: 53.75em) { .mobile-btn { display: none; } }

.nav { display: none; margin-bottom: 0; }
.nav ul { width: 100%; display: block; }
.nav ul li { width: 100%; margin: 0; }
.nav ul li a, .nav ul li a:visited, .nav ul li a:link { width: 100%; display: block; padding-left: 19px; padding-top: 25px; padding-bottom: 25px; background: grey; color: rgba(255, 255, 255, 0.6); border-bottom: 2px solid #535353; }
.nav ul li a:hover, .nav ul li a:active { background: #43CCFF; color: white; }
.nav ul li.current_page_item, .nav ul li.active { background: #43CCFF; color: white; }
@media screen and (min-width: 53.75em) { .nav { display: block; width: 33%; float: right; margin-bottom: 0; }
  .nav ul { display-style: none; width: auto; float: right; margin-top: 1.875rem; margin-bottom: 2rem; margin-left: 1.25rem; }
  .nav ul li { width: auto; float: left; margin-right: 0.9375rem; text-transform: uppercase; }
  .nav ul li a, .nav ul li a:link, .nav ul li a:visited { color: rgba(255, 255, 255, 0.6); padding: 0; background: transparent; border: none; }
  .nav ul li a:hover, .nav ul li a:active, .nav ul li a.active { color: #43CCFF; background: transparent; }
  .nav ul li.current_page_item, .nav ul li.active { background: transparent; }
  .nav ul li.current_page_item a:link, .nav ul li.current_page_item a:visited, .nav ul li.active a:link, .nav ul li.active a:visited { color: #43CCFF; background: transparent; }
  .nav ul li.current_page_item a:hover, .nav ul li.current_page_item a:active, .nav ul li.active a:hover, .nav ul li.active a:active { color: #43CCFF; background: transparent; } }

.nav-mobile, li.mobile, .mobile-only { display: block; clear: both; }
@media screen and (min-width: 53.75em) { .nav-mobile, li.mobile, .mobile-only { display: none; } }

.contact-btn { display: none; }
@media screen and (min-width: 53.75em) { .contact-btn { display: block; } }

aside.contact { display: none; padding-top: 5.625rem; padding-bottom: 5.625rem; background-color: transparent; background-image: -webkit-linear-gradient(, rgba(10, 10, 10, 0.5)); background-image: linear-gradient(to rgba(255, 255, 255, 0),rgba(10, 10, 10, 0.5)); }
aside.contact h2 { font-size: 30px; text-transform: uppercase; }
aside.contact span { width: 60px; display: inline-block; }
aside.contact .btn { font-size: 1rem; background: #43CCFF; -webkit-transition: all 300ms; -moz-transition: all 300ms; transition: all 300ms; }
aside.contact .btn:hover, aside.contact .btn:active { background: #535353; }

.contact-content { background-color: transparent; background-image: -webkit-linear-gradient(, rgba(10, 10, 10, 0.5)); background-image: linear-gradient(to rgba(255, 255, 255, 0.3),rgba(10, 10, 10, 0.5)); padding-top: 20px; }

.page.contact .back-to-top { display: none; }

.contact-profile { display: block; height: 269px; width: 180px; background: red; float: left; margin-right: 30px; overflow: hidden; }
.contact-profile img { max-width: 135%; margin-left: -12.5%; }

/*Posts Normal*/
.posts article { margin-bottom: 0; }
.posts .gallery { width: 100%; position: relative; }
.posts .gallery:before { display: block; content: ""; width: 100%; padding-top: 4.7619%; }
.posts .gallery > .content, .posts .accordion .gallery > .owl-carousel, .accordion .posts .gallery > .owl-carousel { position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; }
.posts .content-area { float: left; display: block; margin-right: 0.41841%; width: 100%; }
.posts .content-area:last-child { margin-right: 0; }
@media screen and (min-width: 53.75em) { .posts .content-area { float: left; display: block; margin-right: 0.41841%; width: 100%; }
  .posts .content-area:last-child { margin-right: 0; } }
.posts .text-area { float: left; display: block; margin-right: 0.41841%; width: 100%; }
.posts .text-area:last-child { margin-right: 0; }
@media screen and (min-width: 53.75em) { .posts .text-area { float: left; display: block; margin-right: 0.41841%; width: 41.42259%; }
  .posts .text-area:last-child { margin-right: 0; } }
.posts .img-area { float: left; display: block; margin-right: 0.41841%; width: 100%; padding-left: 1.1875rem; padding-right: 1.1875rem; }
.posts .img-area:last-child { margin-right: 0; }
@media screen and (min-width: 53.75em) { .posts .img-area { float: left; display: block; margin-right: 0.41841%; width: 49.79079%; margin-left: 8.3682%; padding-left: 0; padding-right: 0; }
  .posts .img-area:last-child { margin-right: 0; } }
.posts .img-area.mobile { opacity: 0; -webkit-transition: opacity 300ms ease; -moz-transition: opacity 300ms ease; transition: opacity 300ms ease; }
@media screen and (min-width: 53.75em) { .posts .img-area.mobile { display: none; } }
.posts .img-area.desktop { opacity: 0; -webkit-transition: opacity 300ms ease; -moz-transition: opacity 300ms ease; transition: opacity 300ms ease; }
@media screen and (max-width: 53.75em) { .posts .img-area.desktop { display: none; } }
.posts header { padding-bottom: 0; }
@media screen and (min-width: 53.75em) { .posts header { padding-left: 0; } }
.posts h1 { margin-bottom: 0.3125rem; }
.posts h2 { color: white; }
.posts section.extended { width: 100%; }
@media screen and (min-width: 53.75em) { .posts section.extended { padding-left: 0; margin-top: 0; } }

/*Post Active*/
.posts article.active { background: rgba(255, 255, 255, 0.1); }
@media screen and (min-width: 53.75em) { .posts article.active { padding-bottom: 0; } }
.posts article.active header { background: none; padding-bottom: 0; }
.posts article.active .content-area { float: left; display: block; margin-right: 0.41841%; width: 100%; }
.posts article.active .content-area:last-child { margin-right: 0; }
.posts article.active .img-area { opacity: 1; }
.posts article.active .img-area.mobile .gallery { min-height: 270px; max-height: 270px; height: 270px; }

main.page { padding-top: 1.625rem; }
@media screen and (min-width: 53.75em) { main.page { padding-top: 3.75rem; } }
@media screen and (min-width: 53.75em) { main.page.alt { padding-top: 0rem; } }

h1.page { width: 100%; font-size: 18px; text-transform: uppercase; color: rgba(255, 255, 255, 0.6); margin-bottom: 0; }
@media screen and (min-width: 53.75em) { h1.page { font-size: 30px; color: white; float: left; display: block; margin-right: 0.41841%; width: 49.79079%; margin-left: 8.3682%; }
  h1.page:last-child { margin-right: 0; } }

h1.mobile-only { display: block; }
@media screen and (min-width: 53.75em) { h1.mobile-only { display: none; } }

article.page { width: 100%; }
@media screen and (min-width: 53.75em) { article.page { float: left; display: block; margin-right: 0.41841%; width: 49.79079%; margin-left: 8.3682%; }
  article.page:last-child { margin-right: 0; } }

section.about-extra { background: rgba(255, 255, 255, 0.1); }
@media screen and (min-width: 53.75em) { section.about-extra article { float: left; display: block; margin-right: 0.41841%; width: 33.05439%; margin-left: 8.3682%; }
  section.about-extra article:last-child { margin-right: 0; } }
section.about-extra h3 { text-transform: uppercase; padding-top: 1.5em; padding-bottom: 0.75em; }
section.about-extra dl { border-bottom: 1px solid rgba(64, 64, 64, 0.6); }
section.about-extra dt, section.about-extra dd { width: 100%; margin: 0; color: white; }
section.about-extra dt { border-top: 1px solid rgba(64, 64, 64, 0.6); padding-top: 0.75em; }
section.about-extra dd { padding-bottom: 0.75em; }
section.about-extra dd dl { margin: 0; border: none; }
section.about-extra dd dl dt, section.about-extra dd dl dd { border: none; padding: 0; }
section.about-extra dd dl dd { color: #929292; }

.profile { width: 160px; margin-bottom: 70px; }
.profile figcaption { padding-top: 10px; padding-bottom: 10px; text-transform: uppercase; text-align: center; background: #646464; font-family: "FFInfoWebTextBook", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; }


