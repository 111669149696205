#{$all-button-inputs},
button {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: $action-color;
  border-radius: $base-border-radius;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: darken($action-color, 15%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}


.btn{
  background: $gray;
  padding:rem(8);
  font-weight:600;
  font-size:em(18);
  line-height:rem(23);
  color:white;
  margin-top:$top-margin;
  display: inline-block;
  &:link, &:visited{
    color:white;
  }
  &:hover, &:active{
    color:white;
    background:$blue;
  }
}

/*SSBA Buttons*/
.ssbp--theme-2 .ssbp-btn:hover, .ssbp--theme-2 .ssbp-btn:focus, .ssbp--theme-2 .ssbp-btn:active, .ssbp--theme-2 .ssbp-btn:visited{
  color: #6e6e6e;
}
.ssbp-list li:first-child a{
  margin-left:0 !important;
}


/*Custom social*/
.block--social{
  span{
    display:none;
  }
  svg{
    width: 25px;
    fill: white;
    @include transition(fill 300ms ease);
  }
 a:link, a:visited{
   svg{
     width: 25px;
     fill: white;
   }
 }
 a:hover, a:active{
   svg{
     width: 25px;
     fill: $blue;
   }
 }


}
